<template>
  <li>
    <router-link v-bind:to="to">
      <i :class="`bx bx-sm bx-${icon}`"></i>
      <span class="links-name">{{ title }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "NavSideBarItem",
  props: {
    to: String,
    icon: String,
    title: String
  }
}
</script>

<style scoped lang="scss">
.sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin: 0 5px;
  list-style: none;
  line-height: 50px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #6A788D;

    transition: all 0.2s ease;

    &.router-link-exact-active {
      color: #fff;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 5%);
      opacity: 1 !important;
    }
  }

  a:hover {
    opacity: 30%;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;
    min-width: 50px;
    line-height: 50px;
  }
}
</style>