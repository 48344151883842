<template>
  <div class="profile-content">
    <div class="profile">
      <div class="profile-details">
        <div class="non-profile-picture" v-if="profile_pic_T.length === 1">{{ profile_pic_T }}</div>
        <img :src="profile_pic_T" alt="" v-else>

        <div class="profile-name">
          <div class="name">{{ first_name }}<div class="balance">${{ balance }}</div></div>
          <div class="premium">{{ premium_render_text }}</div>
        </div>
      </div>
      <i id="options" class='bx bx-sm bx-dots-vertical-rounded'></i>
    </div>
  </div>
</template>

<script>
import { utils } from "@/utils";

export default {
  name: "ProfileSidebar",
  props: {
    first_name: String,
    balance: Number,
    premium_expires_at: String,
    profile_pic_T: String, // can be an URL or a single character representing the first letter of the first name
  },
  computed: {
    premium_render_text() {
      let left_days = utils.days_diff(utils.current_utc_date(), new Date(this.premium_expires_at));
      if (left_days > 0) {
        return `Premium (${left_days} days left)`;
      } else {
        return "Free user";
      }
    }
  }
}
</script>

<style scoped lang="scss">
$profile-pic-border-radius: 27px;
$name-premium-margin: 5px;


.sidebar .profile-content {
  color: #fff;
  width: 100%;

  .profile {
    position: relative;
    padding: 10px 6px;
    height: 60px;

    .profile-details {
      display: flex;
      align-items: center;

      img {
        height: 54px;
        width: 54px;

        object-fit: cover;
        border-radius: $profile-pic-border-radius;
      }

      .non-profile-picture {
        height: 54px;
        width: 54px;
        border-radius: $profile-pic-border-radius;
        background-color: #3f9ff8;
        color: #fff;
        font-size: 24px;
        text-align: center;
        line-height: 54px;
      }

      .profile-name {
        margin-left: 15px;
      }

      .name {
        margin-bottom: $name-premium-margin;

        align-items: center;
        display: flex;
        font-size: 21px;
        font-weight: 500;

        .balance {
          display: inline;
          padding: 3px;
          background-color: #72B0EE;
          border-radius: 8px;

          margin-left: 8px;

          font-weight: 400;
          font-size: 12px;
        }
      }

      .premium {
        margin-top: $name-premium-margin;
        color: #6A788D;
        font-size: 14px;
        font-weight: 400;
      }
    }

    #options {
      color: #6A788D;

      position: absolute;
      left: 88%;
      bottom: 7px;
      transform: translateX(-50%);
    }
  }
}
</style>