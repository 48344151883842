<template>
  <LoadingComponent v-if="isLoading" class="full-center" />
  <div class="home" v-else>
    <h1>Hi, {{ user.first_name }}</h1>
    <div class="cards boxes">
      <div class="box">
        <img src="../assets/lock.svg" alt="Telegram ID">
        <div class="content">
          <h3>Telegram ID</h3>
          <p>{{ user.user_id }}</p>
        </div>

      </div>
      <div class="box">
        <div class="yellow-border circle">{{ stats.sessions_amount }}</div>
        <div class="content">
          <h3>Sessions</h3>
          <span @click="showSessionsManageModal = true">Manage</span>
        </div>
      </div>
      <div class="box">
        <div class="purple-border circle">{{ stats.links_amount }}</div>
        <div class="content">
          <h3>Links</h3>
          <router-link to="/shortener">New</router-link>
        </div>
      </div>
    </div>
  </div>

  <SessionsManageModal :show="showSessionsManageModal" @close="showSessionsManageModal = false" />
</template>

<script>
import store from "@/store";
import LoadingComponent from "@/components/LoadingComponent";
import SessionsManageModal from "@/modal/SessionsManageModal";

export default {
  name: 'HomeView',
  components: { LoadingComponent, SessionsManageModal },
  data() {
    return {
      isLoading: true,
      showSessionsManageModal: false
    }
  },
  computed: {
    user() {
      return store.state.user;
    },
    stats() {
      return store.state.stats;
    },
  },
  async beforeCreate() {
    await store.commit("updateStats");
    this.isLoading = false;
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

$circle-size: 57px;
$circle-border-width: 3px;

.current-content .home {
  color: #fff;

  padding: $current-view-padding;

  .circle {
    font-size: 24px;
    font-weight: 600;

    border-radius: 100%;
    border-style: solid;
    border-width: $circle-border-width;

    margin-top: -$circle-border-width;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cards {
    margin-top: 50px;
  }

  .boxes {
    display: flex;
    .box {
      h3 {
        font-weight: 500;
      }
      p {
        font-size: 14px;
        color: #C8C8C8;
      }
      span, a {
        color: #72B0EE;
        font-weight: 600;
        transition: all 0.2s ease;
        text-decoration: none;
      }

      span:hover, a:hover {
        cursor: pointer;
        color: #7277EE;
      }

      width: 200px;
      height: 200px;
      border-radius: 10px;
      background: #171D26;

      margin-right: 20px;

      padding: 35px 25px;

      .circle {
        width: $circle-size;
        height: $circle-size;
      }

      .content {
        margin-top: 25px;
        h3 {
          margin-bottom: 7px;
        }
      }
    }
  }
}
</style>