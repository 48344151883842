import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import { api } from "@/api";
import { utils } from "@/utils";

import "./styles/app.scss";

let oauth_object = Object.fromEntries(
    new URLSearchParams(window.location.search)
);
let init_app = () => {
    setInterval(async () => api.send_keepalive(), 30000);  // every 30 seconds

    createApp(App).use(store).use(router).mount('#app');
};


if (Object.keys(oauth_object).length > 0) {
    api
        .telegram_oauth(oauth_object)
        .catch((e) => {
            alert(e);
            utils.load_login_page();
        })
        .then(r => {
            if (r.flag === "NEW_SESSION_CREATED") {
                api.set_access_token(r["access_token"]);
            }
            init_app();
        });
} else if (api.accessToken === undefined) {
    utils.load_login_page();
} else {
    init_app();
}

