import ky from "ky";
import { config } from "@/config";
import {utils} from "@/utils";
import Cookies from "js-cookie";

class API {
  constructor(accessToken, hooks) {
    this.accessToken = accessToken;
    this.hooks = hooks;

    this.client = ky.extend({
      prefixUrl: config.backend_url,
      hooks: {
        beforeRequest: [
          (request) => {
            let access_token = this.get_access_token();
            if (access_token !== undefined && access_token !== null) {
              request.headers.set(
                "Authorization",
                "Bearer " + btoa(access_token)
              );
            }
          },
        ],
        afterResponse: [
          async (request, options, response) => {
            if (response.status === 200) {
              return;
            }
            let { error_name, error_message, guard } = await response.json();
            if (guard === "auth")
              utils.load_login_page();

            if (error_message === "The requested auth parameters are expired.") {
              alert("Something wrong happend, please login in again.");
              return utils.load_login_page();
            }

            throw new Error(
              `Error while KY request:\nURL: ${request.url}\n\nMessage:${error_name}\n${error_message}`
            );
          },
        ],
      },
    });
  }

  set_access_token(value) {
    this.accessToken = value;

    if (this.hooks.accessTokenChanged instanceof Function) {
      this.hooks.accessTokenChanged(value);
    }
  }

  get_access_token() {
    if (this.hooks.getAccessTokenOverride instanceof Function) {
      return this.hooks.getAccessTokenOverride();
    }
    return this.accessToken;
  }

  async telegram_oauth(oauth_callback) {
    return await this.client
      .post("auth/telegram", { json: { user: oauth_callback } })
      .json();
  }

  async send_keepalive() {
    return await this.client.get("session/keepalive", {
      retry: { limit: 3 },
      timeout: 10000
    }).json();
  }

  async logout() {
    return await this.client.get("auth/logout").json();
  }

  async get_self() {
    return await this.client.get("me/").json();
  }

  async get_stats() {
    return await this.client.get("me/statistics").json();
  }

  async get_short_links(skip = 0, limit = 15) {
    const query = new URLSearchParams();
    query.set("skip", skip.toString());
    query.set("limit", limit.toString());
    return await this.client.get(`shortener/links?${query.toString()}`).json();
  }

  async create_short_link(name, desc, destination, redirect_type) {
    return await this.client.post("shortener/create", {
      json: {
        "name": name,
        "description": desc,
        "destination": destination,
        "redirect_type": redirect_type,
        "domain": "craff.to"
      }
    }).json();
  }
}

export default API;
export let api = new API(Cookies.get("access_token"), {
  accessTokenChanged: value => Cookies.set("access_token", value),
  getAccessTokenOverride: () => Cookies.get("access_token"),
});
