<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBlank"
}
</script>
