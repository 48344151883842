<template>
  <Transition name="modal">
    <ModalBlank v-if="show">
      <div class="modal-container">
        <img class="close-btn" src="../assets/close.svg" @click="$emit('close')" alt="">
      </div>
    </ModalBlank>
  </Transition>
</template>

<script>
import ModalBlank from "@/components/ModalBlank";

export default {
  name: "SessionsManageModal",
  components: { ModalBlank },
  props: {
    show: Boolean
  }
}
</script>

<style scoped lang="scss">
@import "@/modal/styles/modal.scss";
.close-btn {
  position: absolute;
  right: 0;
  margin: 20px;
  cursor: pointer;
}

.modal-container {
  @include modal-base();

  width: 960px;
  height: 670px;

  background-color: #171D26;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>