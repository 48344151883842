import { createRouter, createWebHistory } from 'vue-router'

import HomeView from "@/views/HomeView";
import LoadingComponent from "@/components/LoadingComponent";

let full_centered_loading = {
  template: `<LoadingComponent class="full-center" />`,
  components: { LoadingComponent }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/shortener',
    name: 'shortener',
    component: () => import(/* webpackChunkName: "shortener" */ '../views/ShortenerView.vue'),
    loading: full_centered_loading
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
