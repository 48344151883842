import { createStore } from 'vuex'
import { api } from "@/api";

export default createStore({
  state() {
    return {
      user: null,
      stats: null
    };
  },
  mutations: {
    async updateUser(state) {
      state.user = await api.get_self();
    },
    async updateStats(state) {
      state.stats = await api.get_stats();
    }
  }
});
