import { config } from "@/config";

export let utils = {
    load_login_page() {
        window.location.replace(config.login_frontend_url);
    },
    current_utc_date() {
        return new Date(Date.now() + new Date().getTimezoneOffset() * 60000);
    },
    days_diff(date1, date2) {
        return Math.floor(
            (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24)
        );
    }
};