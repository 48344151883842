<template>
  <div class="dot-flashing"></div>
</template>

<script>
export default {
  name: "LoadingComponent"
}
</script>

<style scoped lang="scss">

</style>