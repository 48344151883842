<template>
  <div class="sidebar">
    <SidebarLogo />
    <ul class="nav-list" style="padding-top: 20px">
      <NavSideBarItem to="/" icon="home" title="Dashboard" />
      <NavSideBarItem to="/shortener" icon="link" title="Shortener" />
    </ul>
    <LoadingComponent v-if="isLoading" class="profile-sidebar v-center" />
    <ProfileSidebar class="profile-sidebar" v-else
                    v-bind:profile_pic_T="profile_pic_T"
                    v-bind:first_name="user.first_name"
                    v-bind:balance="user.balance"
                    v-bind:premium_expires_at="user.subscription.active_until" />
  </div>
  <div class="current-content">
    <LoadingComponent v-if="isLoading" class="full-center" />
    <router-view v-else />
  </div>
</template>


<script>
import SidebarLogo from "@/components/SidebarLogo";
import NavSideBarItem from "@/components/NavSideBarItem";
import ProfileSidebar from "@/components/ProfileSidebar";
import LoadingComponent from "@/components/LoadingComponent";
import store from "@/store";

export default {
  components: { SidebarLogo, NavSideBarItem, ProfileSidebar, LoadingComponent },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    user() {
      return store.state.user;
    },
    profile_pic_T() {
      if (this.user.photo_url !== null) {
        return this.user.photo_url;
      } else {
        return this.user.first_name[0].toUpperCase();
      }
    }
  },
  async beforeCreate() {
    await store.commit("updateUser");
    this.isLoading = false;
  }
};
</script>

<style scoped lang="scss">
$sidebar-width: 350px;

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: $sidebar-width;
  background: #171D26;
  padding: 6px 14px;
}

.sidebar ul {
  margin-top: 40px;
}

.profile-sidebar {
  left: 0;
  bottom: 0;
  margin-bottom: 40px;
  margin-left: 15px;

  position: absolute;
}

.current-content {
  position: absolute;
  height: 100%;
  width: calc(100% - $sidebar-width);
  left: $sidebar-width;
  background-color: #111926;
}
</style>